<template>
  <!-- 结算管理 - 催收单审核 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">催收单审核</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="付款机构" class="searchboxItem ci-full">
              <span class="itemLabel">付款机构:</span>
              <el-input
                v-model="seachData.payerName"
                type="text"
                size="small"
                placeholder="请输入付款机构"
                clearable
              />
            </div>
            <div title="培训机构" class="searchboxItem ci-full">
              <span class="itemLabel">培训机构:</span>
              <el-select
                size="small"
                v-model="seachData.compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="快递状态" class="searchboxItem ci-full">
              <span class="itemLabel">快递状态:</span>
              <el-select
                size="small"
                v-model="seachData.expressDeliveryState"
                clearable
                placeholder="请选择快递状态"
              >
                <el-option
                  v-for="item in expressDeliveryStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="催收函状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 6rem">催收函状态:</span>
              <el-select
                size="small"
                v-model="seachData.collectionLetterState"
                clearable
                placeholder="请选择催收函状态"
              >
                <el-option
                  v-for="item in collectionLetterStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              ><el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="50"
              />
              <el-table-column
                label="培训机构"
                align="left"
                show-overflow-tooltip
                prop="compName"
                minWidth="180"
              />
              <el-table-column
                label="付款机构"
                align="left"
                show-overflow-tooltip
                prop="payerName"
                minWidth="180"
              />
              <el-table-column
                label="催收金额"
                align="left"
                show-overflow-tooltip
                prop="collectionAmount"
                minWidth="100"
              />
              <el-table-column
                label="收件人信息"
                align="left"
                show-overflow-tooltip
                prop="addresseeName"
                minWidth="180"
              />
              <el-table-column
                label="快递单号"
                align="left"
                show-overflow-tooltip
                prop="expressDeliveryNumber"
                minWidth="150"
              />
              <el-table-column
                label="快递状况"
                align="left"
                show-overflow-tooltip
                prop="expressDeliveryState"
                minWidth="100"
              >
                <template slot-scope="{ row }">
                  {{
                    $setDictionary(
                      "EXPRESS_DELIVERY_STATE",
                      row.expressDeliveryState
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="商务备注"
                align="left"
                show-overflow-tooltip
                prop="remark"
                minWidth="150"
              />
              <el-table-column
                label="催收函状态"
                align="left"
                show-overflow-tooltip
                prop="collectionLetterState"
                minWidth="120"
              >
                <template slot-scope="{ row }">
                  {{
                    $setDictionary(
                      "COLLECTION_LETTER_STATE",
                      row.collectionLetterState
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="生成日期"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                minWidth="150"
              />
              <el-table-column
                label="经办人"
                align="left"
                show-overflow-tooltip
                prop="createName"
                minWidth="100"
              />
              <el-table-column
                label="审核人"
                align="left"
                show-overflow-tooltip
                prop="reviewerName"
                minWidth="100"
              />
              <el-table-column
                label="审核时间"
                align="left"
                show-overflow-tooltip
                prop="reviewTime"
                minWidth="150"
              />
              <el-table-column
                label="驳回原因"
                align="left"
                show-overflow-tooltip
                prop="refuseRemark"
                minWidth="200"
              />
              <el-table-column
                label="操作"
                align="center"
                width="150"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="addWaitCollection(scope.row)"
                    >查看</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    :disabled="scope.row.reviewTime"
                    @click="examineAndVerify(scope.row, '10')"
                    >审核</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    :disabled="scope.row.reviewTime"
                    @click="examineAndVerify(scope.row, '20')"
                    >驳回</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 催收单详情 -->
    <el-dialog
      title="查看催收单"
      :visible.sync="dialogDataState"
      v-if="dialogDataState"
      :close-on-click-modal="false"
      width="600px"
      top="5%"
      center
    >
      <el-form
        ref="dialogForm"
        :model="dialogData"
        label-width="110px"
        size="small"
        disabled
      >
        <el-form-item
          label="付款机构名称"
          prop="payerName"
          :rules="[
            { required: true, message: '请输入付款机构名称', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="dialogData.payerName"
            type="text"
            size="small"
            placeholder="请输入付款机构名称"
          />
        </el-form-item>
        <el-form-item label="邮寄地址">
          <el-input
            v-model="dialogData.address"
            type="text"
            size="small"
            placeholder="请输入邮寄地址"
          />
        </el-form-item>
        <el-form-item label="收件人姓名">
          <el-input
            v-model="dialogData.addresseeName"
            type="text"
            size="small"
            placeholder="请输入收件人姓名"
          />
        </el-form-item>
        <el-form-item
          label="收件人电话"
          prop="addresseeMobile"
          :rules="[
            {
              pattern: /^1[3456789]\d{9}$/,
              message: '请输入正确电话',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <el-input
            v-model="dialogData.addresseeMobile"
            type="text"
            size="small"
            placeholder="请输入收件人电话"
          />
        </el-form-item>
        <el-form-item label="催款金额小写">
          <el-input
            v-model="dialogData.collectionAmount"
            type="text"
            size="small"
            placeholder="请输入催款金额小写"
          />
        </el-form-item>
        <el-form-item label="催款金额大写">
          <el-input
            v-model="dialogData.collectionAmountChinese"
            type="text"
            size="small"
            placeholder="请输入催款金额大写"
          />
        </el-form-item>
        <el-form-item label="审核人">
          <el-select
            size="small"
            v-model="dialogData.reviewerId"
            placeholder="请选择审核人"
          >
            <el-option
              v-for="item in reviewerList"
              :key="item.adminId"
              :label="item.fullname"
              :value="item.adminId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批人">
          <el-select
            size="small"
            v-model="dialogData.approverId"
            placeholder="请选择审批人"
          >
            <el-option
              v-for="item in approverList"
              :key="item.adminId"
              :label="item.fullname"
              :value="item.adminId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0">
          <el-table
            :data="dialogData.billList"
            size="mini"
            height="150px"
            stripe
            style="width: 100%"
          >
            <el-table-column label="序号" align="center" type="index" width="50px" />
            <el-table-column
              prop="billNo"
              label="结算单号">
            </el-table-column>
            <el-table-column
              prop="payerAmount"
              label="结算金额"
              width="110">
            </el-table-column>
            <el-table-column
              prop="settlementAmount"
              label="应收金额"
              width="110">
            </el-table-column>
            <el-table-column
              prop="arrearsAmount"
              label="欠款金额"
              width="110">
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 驳回原因 -->
    <el-dialog
      title="驳回原因"
      :visible.sync="rejectData.rejecDialogVisible"
      width="500px"
      top="10%"
      center
      :before-close="closeSettleAccounts"
      ><el-form
        :model="rejectData"
        :rules="rejectDataRules"
        ref="rejectData"
        label-width="100px"
        class="demo-ruleForm"
        size="small"
      >
        <el-form-item
          label="驳回原因"
          :rules="[
            { required: true, message: '请输入驳回原因', trigger: 'blur' },
          ]"
          prop="refuseRemark"
        >
          <el-input v-model="rejectData.refuseRemark" maxlength="50" placeholder="请输入驳回原因"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="bgc-bv" @click="closeSettleAccounts">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="okSettleAccounts"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  <script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "ywCollectionReviewList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据集
      seachData: {
        payerName: "", // 付款机构
        compId: "", // 机构id
        expressDeliveryState: "", // 快递状态
        collectionLetterState: "", // 催收函状态
      },
      expressDeliveryStateList: [], // 快递状态
      collectionLetterStateList: [], // 催收函状态
      CompanyList: [], // 机构list
      // 查看催收单弹窗数据
      dialogDataState: false,
      reviewerList: [], //审核人列表
      approverList: [], //审批人列表
      // 查看催收单
      dialogData: {
        payerName: "", // 付款机构名称
        address: "", // 邮寄地址
        addresseeName: "", // 收件人姓名
        addresseeMobile: "", // 收件人电话
        collectionAmount: "", // 催款金额小写
        collectionAmountChinese: "", // 催款金额大写
        billList: [], //结算单list
        reviewerId: "", // 审核人
        approverId: "", // 审批人
      },
      // 驳回数据
      rejectData: {
        rejecDialogVisible: false, // 驳回弹框
        collectionLetterId: "", // 催收单id
        refuseRemark: "", // 驳回
      },
    };
  },
  created() {
    this.getDictionary();
    this.getReviewer();
    this.getApprover();
  },
  mounted() {},
  computed: {},
  methods: {
    // 获取字典
    getDictionary() {
      // 快递状态
      let arr = this.$setDictionary("EXPRESS_DELIVERY_STATE", "list");
      for (const key in arr) {
        this.expressDeliveryStateList.push({
          label: arr[key],
          value: key,
        });
      }
      // 催收函状态
      let arr1 = this.$setDictionary("COLLECTION_LETTER_STATE", "list");
      for (const key in arr1) {
        this.collectionLetterStateList.push({
          label: arr1[key],
          value: key,
        });
      }
    },
    // 机构名称
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            console.log(res);
            if (res.status == 0) {
              this.CompanyList = res.data || [];
              console.log(this.CompanyList);
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 初始化获取列表
    getData(pageNum = 1) {
      const params = {
        // 当前页数
        pageNum: pageNum,
        // 每页条数
        pageSize: this.pageSize,
      };
      // 付款机构
      if (this.seachData.payerName) {
        params.payerName = this.seachData.payerName;
      }
      // 机构id
      if (this.seachData.compId) {
        params.compId = this.seachData.compId;
      }
      // 快递状态
      if (this.seachData.expressDeliveryState) {
        params.expressDeliveryState = this.seachData.expressDeliveryState;
      }
      // 催收函状态
      if (this.seachData.collectionLetterState) {
        params.collectionLetterState = this.seachData.collectionLetterState;
      }
      this.doFetch({
        url: "/biz/collectionLetter/review/pageList",
        params,
        pageNum,
      });
    },
    // 查看催收单
    addWaitCollection(row) {
      this.$post("/biz/business/collectionLetterBill/getInfo", {
        collectionLetterId: row.collectionLetterId,
      })
        .then((res) => {
          this.dialogData = Object.assign(this.dialogData, res.data); //赋值
          this.dialogDataState = true;
        })
        .catch(() => {
          return;
        });
    },
    // 弹窗 获取审核人列表
    getReviewer() {
      this.$post("/biz/collectionLetter/getReviewer", {}).then((res) => {
        this.reviewerList = res.data || [];
      });
    },
    // 弹窗 获取审批人列表
    getApprover() {
      this.$post("/biz/collectionLetter/getApprover", {}).then((res) => {
        this.approverList = res.data || [];
      });
    },
    // 审核/驳回
    examineAndVerify(row, reviewState) {
      if (reviewState == "10") {
        this.$post("/biz/collectionLetter/review/handle", {
          collectionLetterId: row.collectionLetterId, // 催收单id
          reviewState, // 审核状态：10-审核通过 20-拒绝
        }).then((res) => {
          this.$message.success("审核结算单成功！");
          this.dialogDataState = false;
          this.getData(-1);
        });
      } else {
        this.rejectData.collectionLetterId = row.collectionLetterId;
        this.rejectData.rejecDialogVisible = true;
      }
    },
    // 驳回确定
    okSettleAccounts() {
      this.$refs["rejectData"].validate((valid) => {
        if (valid) {
          this.$post("/biz/collectionLetter/review/handle", {
            collectionLetterId: this.rejectData.collectionLetterId, // 催收单id
            reviewState: "20", // 审核状态：10-审核通过 20-拒绝
            refuseRemark: this.rejectData.refuseRemark // 驳回原因
          }).then((res) => {
            this.$message.success("驳回审核结算单成功！");
            this.closeSettleAccounts();
          });
        }
      });
    },
    // 驳回弹框关闭
    closeSettleAccounts() {
      // 将data中的某个数据重置到初始状态(赵金福-专用方法);
      this.rejectData = this.$options.data().rejectData;
      this.getData(-1);
    },
  },
};
</script>
  <style lang="less" scoped>
</style>
  